/* eslint-disable */
//import '@babel/polyfill';
import Cookies from 'js-cookie';
import { saveContactInformation } from './contact';
import { login, logout } from './login';

const contactForm = document.querySelector('.contact-form');
const loginForm = document.querySelector('.login-form');

// **** CONTACT FORM **** //
if (contactForm) {
  contactForm.addEventListener('submit', async (e) => {
    e.preventDefault();

    const firstName = document.getElementById('fname').value;
    const lastName = document.getElementById('lname').value;
    const email = document.getElementById('email').value;
    const subject = document.getElementById('subject').value;
    const message = document.getElementById('message').value;

    document.getElementById("sendBtn").disabled = true;

    await saveContactInformation(firstName, lastName, email, subject, message);
  });
}

// **** COOKIES **** //
const consentPropertyName = 'angelodebruyne_cookies_consent';

const shouldShowPopup = () => !Cookies.get(consentPropertyName);
const acceptCookieStorage = () =>
  Cookies.set(consentPropertyName, true, { expires: 365 });
const declineCookieStorage = () =>
  Cookies.set(consentPropertyName, false, { expires: 365 });

window.onload = () => {
  const consentPopup = document.getElementById('consent-popup');
  const acceptBtn = document.getElementById('accept');
  const declineBtn = document.getElementById('decline');

  const acceptFn = (event) => {
    acceptCookieStorage();
    //location.reload();
    consentPopup.classList.add('hidden');
  };

  const declineFn = (event) => {
    declineCookieStorage();
    consentPopup.classList.add('hidden');
  };

  acceptBtn.addEventListener('click', acceptFn);
  declineBtn.addEventListener('click', declineFn);

  if (shouldShowPopup()) {
    setTimeout(() => {
      consentPopup.classList.remove('hidden');
    }, 5000);
  }
};

if (loginForm) {
  loginForm.addEventListener('submit', (e) => {
    e.preventDefault();

    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    login(email, password);
  });
}


// **** BACKGROUND **** //
var images = [
  "../images/background-1.jpg", "../images/background-0.jpg", "../images/background-2.jpg", "../images/background-4.jpg"
]

var imageHead = document.getElementById("image-head");

if (imageHead) {
  var i = 0;
  setInterval(function () {
    imageHead.style.backgroundImage = "url(" + images[i] + ")";
    i = i + 1;
    if (i == images.length) {
      i = 0;
    }
  }, 5000);
}
